<template>
  <v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="text-align: left">
              templateId
            </th>
            <th class="text-left" style="text-align: left">
              language
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in templates" :key="item.templateId + '.' + item.language" style="cursor: pointer" v-on:click="getTemplate(item.templateId, item.language)">
            <td>{{item.templateId}}</td>
            <td>{{item.language}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
        color="primary"
        elevation="2"
        class="white--text" style="margin-top: 1rem;" v-on:click="createTemplate"
    >New template</v-btn>
    <v-overlay :value="chosen" light :dark="false">
      <v-card v-click-outside="closePopup" style="max-height: calc(100vh - 2rem); overflow-y: auto;">
        <v-container style="display: flex; height: fit-content;">
          <v-form>
            <template v-slot:default>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                        outlined
                        v-model="templateId"
                        label="templateId"
                        id="templateId"
                        type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        outlined
                        v-model="localization"
                        label="language"
                        id="language"
                        type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                        outlined
                        v-model="templateTitleString"
                        v-on:input="updateTitle"
                        label="title"
                        type="text"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                        outlined
                        v-model="templateBodyString"
                        v-on:input="updateBody"
                        label="body"
                        type="text"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                        outlined
                        v-model="templateAttributesString"
                        v-on:input="updateAttributes"
                        label="attributes (CSS-like syntax)"
                        type="text"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <iframe
                        :src="iframeSrc" :key="iframeSrc"
                        width="100%" height="160" frameBorder="0"
                    ></iframe>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="min-width: fit-content">
                    <v-btn
                        elevation="0"
                        v-on:click="chosen = false"
                    >Discard</v-btn>
                  </v-col>
                  <v-col style="min-width: fit-content">
                    <v-btn
                        color="primary"
                        elevation="2"
                        class="white--text"
                        v-on:click="saveTemplate"
                    >Save template</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-form>
        </v-container>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'MainTemplates',
  data () {
    return {
      templateTitleString: "",
      templateBodyString: "",
      templateAttributesString: "",
      templateId: "default",
      localization: "en-US",
      localizations: [],
      iframeSrc: "",
      templates: [],
      chosen: false,
      template: {},
      defaultLocalization: "en-US",
      defaultTemplateId: "default",
      defaultTemplate: {
        data: {
          title: [{elementType: "BOLD", content: "N", color: "#ff0000"}, {elementType: "TEXT", content: "etflix"}],
          body: [{elementType: "TEXT", content: "Hey, "}, {elementType: "TEXT", content: "name", replace: true}, {elementType: "TEXT", content: "! \n"}, {elementType: "TEXT", content: "This is an example "}, {elementType: "BOLD", content: "Netflix Alert Message \ud83d\ude00"}, {elementType: "TEXT", content: ". "}]
        },
        attributes: {
          background: "#1f232e",
          color: "#ffffff"
        },
        cta: {action: "GET_BONUS", feedbackType: "page", url: "plug"}
      },
    };
  },
  mounted() {
    this.getAllTemplates();
  },
  methods: {
    getAllTemplates: function () {
      const context = this;
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "getAllTemplates",
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function (res) {
        return res.json();
      }).then(function (res){
        if (!res.success)
          return;
        context.templates = res.templates;
      });
    },
    closePopup: function () {
      this.chosen = false;
    },
    getTemplate: function (templateId, language) {
      this.templateId = templateId;
      this.localization = language;
      const context = this;
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "getTemplate",
          "templateId": this.templateId,
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function (res) {
        return res.json();
      }).then(function (res){
        if (!res.success)
          return;
        context.localizations = Object.keys(res.template);

        context.template = res.template[context.localization];

        context.openTemplate();
      });
    },
    saveTemplate: function () {
      const context = this;
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "saveTemplate",
          "authToken": localStorage.getItem("authToken"),
          "templateId": this.templateId,
          "language": this.localization,
          "attributes": this.template.attributes,
          "data": this.template.data,
          "cta": this.template.cta,
        })
      }).then(function (res) {
        return res.json();
      }).then(function (res) {
        if (res.success) {
          context.getAllTemplates();
          context.chosen = false;
        }
      });
    },
    createTemplate: function () {
      this.template = this.defaultTemplate;
      this.templateId = this.defaultTemplateId;
      this.localization = this.defaultLocalization;
      this.openTemplate();
    },
    openTemplate: function () {
      this.templateTitleString = JSON.stringify(this.template.data.title, null, 2);
      this.templateBodyString = JSON.stringify(this.template.data.body, null, 2);
      this.templateAttributesString = JSON.stringify(this.template.attributes, null, 2);

      this.makeNotification();

      this.chosen = true;
    },
    updateBody: function () {
      this.template.data.body = JSON.parse(this.templateBodyString);
      this.makeNotification();
    },
    updateTitle: function () {
      this.template.data.title = JSON.parse(this.templateTitleString);
      this.makeNotification();
    },
    updateAttributes: function () {
      this.template.attributes = JSON.parse(this.templateAttributesString);
      this.makeNotification();
    },
    makeNotification: function () {
      const notificationTitle = this.stringBuilder(this.template.data.title);
      const notificationBody = this.stringBuilder(this.template.data.body);
      const style = this.stylize_notification(this.template.attributes)

      const html = `
      <html lang="en">
        <head>
          <link type="text/css" rel="stylesheet" href="https://cdn.significo.net/css/notification.css">
        </head>
        <body style="display: flex; min-width: 20rem;margin: 0;">
          <div class="notification-block" id="notification-block" style="position: relative; margin: auto; padding: 0;width: 100%;">
            <div class="notification" style="${style};margin: auto;">
              <div class="header">
                <p class="title">${notificationTitle}</p>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="dismiss" id="DISMISS_ALERT" width="18" height="18" viewBox="0 0 18 18"><path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/></svg>
                </div>
              </div>
              <div class="body">${notificationBody}</div>
            </div>
          </div>
        </body>
      </html>
      `;

      this.iframeSrc = "data:text/html;charset=utf-8," + encodeURIComponent(html);
    },
    stringBuilder: function(template) {
      "use strict";
      let resString = "";
      for (let i = 0; i < template.length; i++) {
        let strTemp;
        let attrib = "";
        if (template[i].color) {
          attrib = ` style="color:${template[i].color}"`;
        }

        if (template[i].elementType === "TEXT") {
          strTemp = `<span${attrib}>${template[i].content}</span>`;
        } else if (template[i].elementType === "BOLD") {
          strTemp = `<b${attrib}>${template[i].content}</b>`;
        }
        resString += strTemp.replace("\n", "<br>");
      }

      return resString;
    },
    stylize_notification: function(data) {
      "use strict";
      let style = "";
      if (data) {
        for (const [key, value] of Object.entries(data)) {
          style += `${key}:${value};`;
        }
      }
      return style;
    }
  }
}
</script>
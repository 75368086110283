<template>
  <v-container>
    <v-row>
      <v-col class="chart">
        <vue-highcharts :options="deliveryChart" ref="lineCharts" :key="changed"></vue-highcharts>
      </v-col>
      <v-col class="chart">
        <vue-highcharts :options="notificationsTypeChart" ref="lineCharts" :key="changed"></vue-highcharts>
      </v-col>
      <v-col class="chart">
        <vue-highcharts :options="actionsChart" ref="lineCharts" :key="changed"></vue-highcharts>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueHighcharts from 'vue2-highcharts'

export default {
  name: 'MainAnalytics',
  components: {VueHighcharts},
  data() {
    return {
      notificationsTypeChart: {
        chart: {
          type: "pie"
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Types of delivered notifications"
        },
        series: [
          {
            name: "Delivered notifications of this type",
            data: []
          }
        ]
      },
      actionsChart: {
        chart: {
          type: "pie",
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Actions from users"
        },
        series: [
          {
            name: "Notifications with this action taken",
            data: []
          }
        ]
      },
      deliveryChart: {
        chart: {
          type: "pie",
        },
        credits: {
          enabled: false
        },
        title: {
          text: "Delivery status of notifications"
        },
        series: [
          {
            name: "Notifications with this delivery status",
            data: []
          }
        ]
      },
      changed: false
    };
  },
  mounted () {
    this.loadDetails();
    setInterval(
        this.loadDetails,
        60*1000
    );
  },
  methods: {
    loadDetails: function () {
      const context = this;

      return fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "getAnalytics",
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function(res){
        return res.json();
      }).then(function (res) {
        const notificationsTypes = res.notificationsTypes;
        let arr = [];
        for (const key of Object.keys(notificationsTypes))
          arr.push([key, notificationsTypes[key]])

        context.notificationsTypeChart.series[0].data = arr;

        const actions = res.actions;
        arr = [];
        for (const key of Object.keys(actions))
          arr.push([key, actions[key]])

        context.actionsChart.series[0].data = arr;

        context.deliveryChart.series[0].data = [];

        if (res.delivered)
          context.deliveryChart.series[0].data.push(["Delivered", res.delivered]);

        if (res.all !== res.delivered)
          context.deliveryChart.series[0].data.push(["Stuck", res.all - res.delivered]);

        context.changed = true;
      });
    },
  }
}
</script>

<style scoped>
.chart {
  min-width: 15rem;
}
</style>

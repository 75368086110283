<template>
  <v-app>
    <v-container height="350px">
      <v-card
        flat
        outlined
        style="overflow: hidden"
      >
        <v-tabs
            fixed-tabs
            show-arrows
        >
          <v-tab v-on:click="beforeTab = tab; tab = tabs.Details">
            Details
          </v-tab>
          <v-tab v-on:click="beforeTab = tab; tab = tabs.Analytics">
            Analytics
          </v-tab>
          <v-tab v-on:click="beforeTab = tab; tab = tabs.Send">
            Send
          </v-tab>
          <v-tab v-on:click="beforeTab = tab; tab = tabs.Templates">
            Templates
          </v-tab>
          <v-tab v-on:click="beforeTab = tab; tab = tabs.Settings">
            Settings
          </v-tab>
        </v-tabs>
        <v-container>
          <transition v-bind:name="'slide-left'" mode="out-in">
            <MainDetails v-show="tab === tabs.Details"></MainDetails>
          </transition>
          <transition v-bind:name="slide()" mode="out-in">
            <MainSend v-show="tab === tabs.Send"></MainSend>
          </transition>
          <transition v-bind:name="slide()" mode="out-in">
            <MainTemplates v-show="tab === tabs.Templates"></MainTemplates>
          </transition>
          <transition v-bind:name="slide()" mode="out-in">
            <MainAnalytics v-show="tab === tabs.Analytics"></MainAnalytics>
          </transition>
          <transition v-bind:name="slide()" mode="out-in">
            <MainSettings v-show="tab === tabs.Settings"></MainSettings>
          </transition>
        </v-container>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import MainDetails from "@/components/Main/MainDetails"
import MainSettings from "@/components/Main/MainSettings"
import MainSend from "@/components/Main/MainSend"
import MainAnalytics from "@/components/Main/MainAnalytics"
import MainTemplates from "@/components/Main/MainTemplates"

export default {
  name: 'Main',
  components: {MainDetails, MainSettings, MainSend, MainAnalytics, MainTemplates},
  beforeCreate () {
    if (!localStorage.getItem("authToken")) {
      this.$router.push("/signin")
    }
  },
  data () {
    return {
      tabs: {
        Details: 0,
        Analytics: 1,
        Send: 2,
        Templates: 3,
        Settings: 4,
      },
      tab: 0,
      beforeTab: 0
    }
  },
  mounted () {
    this.tab = this.tabs.Details;
  },
  methods: {
    slide: function () {
      return this.tab < this.beforeTab ? 'slide-left' : 'slide-right'
    }
  }
}
</script>
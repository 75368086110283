<template>
  <v-container>
    <v-form>
      <template v-slot:default>
        <v-container>
          <v-row>
            <v-col style="min-width: fit-content">
                <v-text-field
                    outlined  dense
                    v-model="templateId" v-on:input="getTemplate" id="templateId" label="templateId" type="text"></v-text-field>
                <v-select
                    outlined dense
                    :items="localizations"
                    v-model="localization"
                    label="Localization"
                    id="localization"
                    v-on:input="getSubs"
                ></v-select>
            </v-col>
            <v-col style="min-width: fit-content">
                <v-radio-group
                    v-model="destination"
                    mandatory
                    color="primary"
                    column
                >
                  <v-radio
                      label="User"
                      value="user"
                      color="primary"
                  ></v-radio>
                  <v-radio
                      label="Channel"
                      value="channel"
                      color="primary"
                  ></v-radio>
                </v-radio-group>
                <v-text-field
                    outlined dense
                    v-model="destinationId" id="destinationId" v-bind:label="destination" type="text" style="text-transform: capitalize"></v-text-field>
            </v-col>
            <v-col style="min-width: fit-content">
                <p>ctaParams:</p>
                <v-text-field
                    outlined dense
                    v-model="ctaParamsUrl" id="ctaParamsUrl" label="url" type="url" style="text-transform: capitalize"></v-text-field>
                <p>Substitute values:</p>
                <template>
                  <v-text-field
                      outlined dense
                      v-for="item in substitutions"
                      :key="item"
                      v-bind:label="item" type="text"
                      v-model="subsValues[item]"
                  ></v-text-field>
                </template>
                <v-btn
                    color="primary"
                    elevation="2"
                    v-on:click="sendNotification"
                    class="white--text"
                >Send</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-alert
            outlined
            style="margin-top: 2rem;overflow-x: overlay;"
            v-bind:type="alertStatus"
        >{{alertText}}</v-alert>
      </template>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MainSend',
  data () {
    return {
      messageId: "",
      template: {},
      templateId: "",
      localization: "",
      destinationId: "",
      ctaParamsUrl: "",
      destination: "user",
      localizations: [],
      subsKeys: [],
      subsValues: {},
      substitutions: new Set(),
      sent: false,
      alertStatus: "info",
      alertText: "To send a notification fill all the fields and press \"SEND\"."
    };
  },
  computed: {
    ...mapState(['getFreeVideo'])
  },
  watch: {
    getSubsKeys(){
      this.subsKeys = this.substitutions;
    }
  },
  methods: {
    getTemplate: function () {
      const context = this;
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "getTemplate",
          "templateId": context.templateId,
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function (res) {
        return res.json();
      }).then(function (res){
        if (!res.success)
          return;
        context.localizations = Object.keys(res.template);

        context.template = res.template;
      });
    },
    getSubs: function () {
      this.substitutions.clear();

      const template = this.template[this.localization].data;

      for (let i = 0; i < template.title.length; i++)
        if (template.title[i].replace)
          this.substitutions.add(template.title[i].content);

      for (let i = 0; i < template.body.length; i++)
        if (template.body[i].replace)
          this.substitutions.add(template.body[i].content);
    },
    sendNotification: function () {
      const context = this;

      const authToken = localStorage.getItem("authToken");
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "sendNotificationFromWeb",
          "templateId": this.templateId,
          "authToken": authToken,
          "destination": this.destination,
          "destinationId": this.destinationId,
          "localization": this.localization,
          "ctaParams": {
            "url": this.ctaParamsUrl
          },
          "subsValues": this.subsValues
        })
      }).then(function (res) {
        return res.json();
      }).then(function (res){
        context.sent = res.success;
        if (res.success) {
          context.messageId = res.messageId;
          context.alertStatus = "success";
          context.alertText = `Notification sent! MessageId: ${res.messageId}`;
        }else{
          context.alertStatus = "error";
          context.alertText = "Error!";
        }
      });
    }
  }
}
</script>
<template>
  <v-container>
    <v-container>
      <v-row>
        <v-col style="max-width: 20rem;">
          <v-card
              outlined
          >
            <v-card-title style="text-align: center;white-space: nowrap;">Active plan</v-card-title>
            <v-card-text style="text-align: center; font-size: 2rem">{{ customerPlan }}</v-card-text>
            <v-card-actions>
              <v-btn
                  color="#e5e5ea"
                  elevation="0"
                  v-bind="attrs"
                  style="margin: auto;color: #000000;text-align: center"
              >Change</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card
              outlined
              style="height: 100%"
          >
            <v-card-title style="text-align: center">Notifications used</v-card-title>
            <v-card-text style="text-align: center; font-size: 2rem">
                <v-container>
                  <v-row>
                    <v-col style="display: flex;">
                    <v-progress-linear
                        v-bind:value="100 * notificationsToday / freePlanLimit"
                        style="margin: auto"
                    ></v-progress-linear>
                  </v-col>
                    <v-col style="flex-grow: 0.1;text-align: center; font-size: 1rem;display: flex;white-space: nowrap;">
                      {{ notificationsToday }} / {{ freePlanLimit }}
                    </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="text-align: left">
            Parameter
          </th>
          <th class="text-left" style="text-align: left; padding-left: 1.7rem">
            Details
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Your email</td>
          <td>
            <v-text-field
                v-bind:value="email"
                solo flat readonly hide-details
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td>Your domain</td>
          <td>
            <v-text-field
                v-bind:value="customerHost"
                solo flat readonly hide-details
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td>Sent notifications today</td>
          <td>
            <v-text-field
                v-bind:value="notificationsToday"
                solo flat readonly hide-details
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td>Callback for users authentication</td>
          <td>
            <v-text-field
                v-bind:value="authCallback"
                solo flat readonly hide-details
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td>Registered channels</td>
          <td>
            <v-text-field
                v-bind:value="channels"
                solo flat readonly hide-details
            ></v-text-field>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-container style="display: flex">
      <v-btn
          color="#ff3b30"
          elevation="2"
          v-on:click="logOut"
          class="white--text"
          style="margin-left: auto"
      >Log Out</v-btn>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'MainDetails',
  data() {
    return {
      details: [],
      customerHost: '',
      email: "",
      authCallback: "",
      channels: "",
      customerPlan: "Free",
      notificationsToday: 0,
      freePlanLimit: 100
    };
  },
  mounted () {
    this.loadDetails();
  },
  methods: {
    loadDetails: function () {
      const context = this;
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "getAccountDetails",
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function(res){
        return res.json();
      }).then(function (res) {
        if (!res.success) {
          localStorage.removeItem("authToken");
          context.$router.push("/signin")
        }
        context.email = res.email;
        context.customerHost = res.host;
        context.authCallback = res.authCallback;
        context.customerPlan = res.customerPlan;
        context.notificationsToday = res.notificationsToday;
        context.freePlanLimit = res.freePlanLimit;
      });


      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "listChannels",
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function (res2) {
        return res2.json();
      }).then(function (res2) {
        context.channels = res2.channels.toString();
      });
    },
    logOut() {
      const context = this;
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "logOut",
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function (res2) {
        return res2.json();
      }).then(function (res2) {
        if (res2.success) {
          localStorage.removeItem("authToken");
          context.$router.push("/signin");
        }
      });
    }
  }
}
</script>
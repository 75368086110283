<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left" style="text-align: left">
          Parameter
        </th>
        <th class="text-left" style="text-align: left">
          Details
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Customer id</td>
        <td style="max-width: 60vw;padding: 0;">
          <v-text-field
              solo flat filled readonly hide-details full-width
              @focus="$event.target.select();" v-bind:value="customerId"
          ></v-text-field>
        </td>
      </tr>
      <tr>
        <td>Your private key</td>
        <td style="max-width: 60vw;padding: 0;">
          <table style="width: 100%"><tr><td>
          <v-text-field
              solo flat filled readonly hide-details full-width
              @focus="$event.target.select();" v-bind:value="privateKey"
              style="width: available"
          ></v-text-field>
            </td><td style="width: 5rem">
            <v-dialog
                v-model="dialog"
                persistent
                max-width="350"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="#e5e5ea"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    style="margin: auto 1rem;color: #000000;"
                >Reset</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  Reset your private key?
                </v-card-title>
                <v-card-text>
                  This will inactivate your current private key and issue a new one.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="green darken-1"
                      text
                      @click="dialog = false"
                  >
                    Disagree
                  </v-btn>
                  <v-btn
                      color="green darken-1"
                      text
                      @click="resetPrivateKey"
                  >
                    Agree
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </td></tr></table>
        </td>
      </tr>
      <tr>
        <td>DKIM public key</td>
        <td style="max-width: 60vw;padding: 0;">
          <v-text-field
              solo flat filled readonly hide-details full-width
              @focus="$event.target.select();" v-bind:value="dkimPublicKey"
          ></v-text-field>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'MainSettings',
  data() {
    return {
      customerId: '',
      privateKey: '',
      dkimPublicKey: '',
      dialog: false
    };
  },
  mounted () {
    const context = this;
    this.loadSettings().then(function (res){
      context.customerId = res.customerId;
      context.privateKey = res.privateKey;
      context.dkimPublicKey = res.dkimPublicKey;
    });
  },
  methods: {
    loadSettings: function () {
      const prom1 = fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "getDkimPublicKey",
          "authToken": localStorage.getItem("authToken")
        })
      });

      const prom2 = fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "getPrivateKey",
          "authToken": localStorage.getItem("authToken")
        })
      });

      return prom1.then(function(res){
        return res.json();
      }).then(function (res) {
        return {
          customerId: res.customerId,
          dkimPublicKey: res.dkimPublicKey,
        };
      }).then(function (details2) {
        return prom2.then(function (res2) {
          return res2.json();
        }).then(function (res2) {
          details2.privateKey = res2.privateKey;

          return details2;
        });
      });
    },
    resetPrivateKey(){
      this.dialog = false;
      const context = this;
      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "resetPrivateKey",
          "authToken": localStorage.getItem("authToken")
        })
      }).then(function (res) {
        return res.json();
      }).then(function (res) {
        context.privateKey = res.privateKey;
      });
    }
  }
}
</script>
import Vue from 'vue'
import VueRouter from "vue-router"
import SignIn from "@/pages/SignIn";
import Main from "@/pages/Main";
import SignUp from "@/pages/SignUp";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router

<template>
  <v-app>
    <v-form style="min-height: 100vh; height: fit-content; display: flex">
      <div class="signin-box-wrapper">
        <div class="signin-box">
          <img src="resizing.svg" class="logo-text">
          <div class="title">Create account</div>
          <div class="input-wrapper">
            <template>
              <v-text-field
                  outlined dense
                  class="input"
                  :rules="ruleSet1" v-model="email" v-bind:id="email" type="email" label="Email" required></v-text-field>
            </template>
          </div>
          <div class="input-wrapper">
            <template>
              <v-text-field
                  outlined dense
                  class="input"
                  :rules="[rules.required]" v-model="password" v-bind:id="password" type="password" label="Password" required></v-text-field>
            </template>
          </div>
          <div class="input-wrapper">
            <template>
              <v-text-field
                  outlined dense
                  class="input"
                  :rules="[rules.required, value => (this.password === value) || 'Password must match']" v-model="passwordRepeat" v-bind:id="passwordRepeat" type="password" label="Re-enter password" required></v-text-field>
            </template>
          </div>
          <div class="button-wrapper">
            <v-btn
                color="white"
                elevation="0"
                v-on:click="signIn"
                style="margin-left: -16px;text-transform: none;"
            >Sign in instead</v-btn>
            <v-btn
                color="primary"
                elevation="2"
                v-on:click="signIn"
                class="white--text"
                style="margin-left: auto;"
            >Next</v-btn>
          </div>
          <div class="forgotPassword">Forgot password?</div>
        </div>
      </div>
    </v-form>
  </v-app>
</template>

<script>
export default {
  name: 'SignUp',
  beforeCreate () {
    if (localStorage.getItem("authToken")) {
      this.$router.push("/")
    }
  },
  methods: {
    signIn() {
      this.$router.push('/signin');
    },
    signUp: function () {
      const context = this;
      if (!context.email)
        return;
      if (!context.password)
        return;

      fetch("https://significo.net/api", {
        method: "POST",
        mode: 'cors',
        headers: {
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "operationName": "signIn",
          "email": context.email,
          "password": context.password
        })
      }).then(function(res){
        return res.json();
      }).then(function (res) {
        if (!res.success)
          return;
        localStorage.setItem("authToken", res.authToken);

        context.$router.push("/")
      });
    }
  },
  data: () => ({
    email: "",
    password: "",
    passwordRepeat: "",
    ruleSet1: [
      value => !!value || 'Required.',
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail'
      },
    ],
    rules: {
      required: value => !!value || 'Required'
    },
  })
}
</script>

<style scoped>
.signin-box-wrapper{
  margin: auto;
  height: 100%;
  max-height: 100vh;
  display: flex;
}

.signin-box{
  display: flex;
  flex-direction: column;
  margin: auto;
  border: rgba(0, 0, 0, 0.1) solid .1rem;
  border-radius: .5rem;
  padding: 2rem 2.5rem;
}

.signin-box .title {
  margin: 1.5rem 0;
  font-size: 2rem !important;
}

.input-wrapper {
  text-align: right;
  margin-bottom: 1rem;
}

.input-wrapper label {
  margin-right: 1rem;
}

.button-wrapper {
  width: 100%;
  display: flex;
}

.button-wrapper button {
}

.input {
  width: 20rem;
}

.logo-text {
  width: 10rem;
  margin: auto;
}

.forgotPassword {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  width: fit-content;
  margin: 1.2rem auto 0;
}

.forgotPassword:hover {
  color: rgba(0, 0, 0, 1);
}
</style>
